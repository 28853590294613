$(document).ready(function() {
  $(window).on('scroll', function() {
    $('.js-service-intro-chat-bubble').each(function() {
      var bubble = $(this);

      // 画面に表示されていない、かつ、吹き出しが表示領域に入っていない場合
      if(!bubble.hasClass('is-shown') && isBubbleInViewport(bubble, 150)) {
        bubble.addClass('is-shown');
      }
    })
  });
});

// 要素が表示領域に入ったか判定する
function isBubbleInViewport(bubble, offset) {
  var rect = bubble[0].getBoundingClientRect();

  // 吹き出しがある程度表示領域に入ってから表出するように、
  // window.innerHeight から offset に設定した値の分だけマイナス
  return (rect.top >= 0 && rect.bottom <= (window.innerHeight - offset));
}
